import { JsonObjectFeature } from '../../Feature/JsonFeature';
import { styleFunction as cameraStyle } from '../cameraLayer';
import { styleFunction as toxicGasStyle } from '../toxicGasLayer';
import { styleFunction as flammableGasStyle } from '../flammableGasLayer';
import { styleFunction as riskSourceStyle } from '../riskSourceLayer';
import { styleByFeatureType } from '../../style';


export function markerLonLat(layer, coordinate, type, isClear = true) {
  if (layer && isClear) {
    layer.getSource().clear();
  }

  const obj = { featureType: 'point', coordinates: coordinate };
  const feature = JsonObjectFeature(obj);
  switch (type) {
    case 'carmera': // 摄像头
      feature.setStyle(cameraStyle);
      break;
    case 'gas': // 有毒气体
      feature.setStyle(toxicGasStyle);
      break;
    case 'fuelgas': // 可燃气体
      feature.setStyle(flammableGasStyle);
      break;
    case 'source': // 危险源
      feature.setStyle(riskSourceStyle);
      break;
    default:
      feature.setStyle(styleByFeatureType);
      break;
  }
  layer.getSource().addFeature(feature);
}

export function clearLayer(layer) {
  if (layer) {
    layer.getSource().clear();
  }
}
