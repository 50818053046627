import {
  Circle as CircleStyle, Fill, Stroke, Style, Icon, Text,
} from 'ol/style';

const circleStyle = new CircleStyle({
  radius: 5,
  fill: new Fill({
    color: 'rgba(255, 0, 0, 1)',
  }),
  stroke: new Stroke({
    color: 'red',
    width: 1,
  }),
});

const styles = {
  Point: new Style({
    image: circleStyle,
  }),
  LineString: new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  MultiLineString: new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  MultiPoint: new Style({
    image: circleStyle,
  }),
  MultiPolygon: new Style({
    stroke: new Stroke({
      color: 'yellow',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 0, 0.1)',
    }),
  }),
  Polygon: new Style({
    stroke: new Stroke({
      color: 'blue',
      lineDash: [4],
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)',
    }),
  }),
  GeometryCollection: new Style({
    stroke: new Stroke({
      color: 'magenta',
      width: 2,
    }),
    fill: new Fill({
      color: 'magenta',
    }),
    image: new CircleStyle({
      radius: 10,
      fill: null,
      stroke: new Stroke({
        color: 'magenta',
      }),
    }),
  }),
  Circle: new Style({
    stroke: new Stroke({
      color: 'red',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(255,0,0,0.2)',
    }),
  }),
};

/**
 * 根据要素类型，返回样式
 * @param  {Ol.Feature} feature
 * @returns {ol.style} 默认样式
 */
export function styleByFeatureType(feature) {
  return styles[feature.getGeometry().getType()];
}

/**
 * @param  {string} url 图片地址
 * @returns {ol.style} 图片样式
 */
export function styleByImage(url) {
  return new Style({
    image: new Icon({
      scale: 1,
      src: url,
    }),
  });
}

/**
 * @param  { Object } params
 * params = { radius : number, fillColor : string, strokeColor : string, strokeWidth : number }
 * @returns { ol.style } 圆的样式
 */
export function styleByCircle(params) {
  const options = params || {};
  const radius = options.radius || 5;
  const fillColor = options.fillColor || 'rgba(0,0,0,0)'; // 默认为透明色
  const strokeColor = options.strokeColor || 'rgba(0,0,0,1)'; // 默认为黑色
  const strokeWidth = options.strokeWidth || 1; // 默认边框宽度为1

  return new Style({
    image: new CircleStyle({
      radius,
      fill: new Fill({
        color: fillColor,
      }),
      stroke: new Stroke({
        color: strokeColor,
        width: strokeWidth,
      }),
    }),
  });
}

const styleCache = {};

export function clusterStyleFunction(feature) {
  const size = feature.get('features').length;
  let style = styleCache[size];
  if (!style) {
    style = new Style({
      image: new CircleStyle({
        radius: 10,
        stroke: new Stroke({
          color: '#fff',
        }),
        fill: new Fill({
          color: '#3399CC',
        }),
      }),
      text: new Text({
        text: size.toString(),
        fill: new Fill({
          color: '#fff',
        }),
      }),
    });
    styleCache[size] = style;
  }
  return style;
}
