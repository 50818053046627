<template>
  <div class="homepage">
    <div class="swiper">
      <el-carousel class="carousel" :interval="4000" :loop="true" arrow="always">
        <el-carousel-item>
          <img src="../../assets/imgs/21.png" alt="">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../assets/imgs/22.png" alt="">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../assets/imgs/23.png" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="notices">
      <div class="left">
        <el-carousel class="news-swiper" :interval="5000" height="411px" :loop="true" arrow="always">
          <el-carousel-item v-for="(item,index) in picNews" :key="index">
            <img :src="item.titleImg[0]" alt="" title="查看详情"  @click="showNewsDetail(item.article)">
            <p class="title" :title="item.article.title">{{item.article.title}}</p>
          </el-carousel-item>

        </el-carousel>
      </div>
      <div class="right">
        <div class="tabs">
          <div class="tab-item"
            v-for="(item, index) in tabs"
            :key="item.name"
            :class="{'active': currentIndex === index}"
            @mouseenter="handleClick(item, index)">
            <span>{{item.label}}</span>
          </div>
        </div>
        <!-- 新闻中心 -->
        <div class="notice" v-if="currentIndex === 0">
          <el-table
            class="table-head-blue"
            :data="recentNews"
            style="width: 100%"
            height="300px"
            @row-click="showDetail">
            <el-table-column align="center" prop="title" label="标题"></el-table-column>
            <el-table-column align="center" prop="createTime" width="150px" :formatter="formatterTime" label="发布日期"></el-table-column>
          </el-table>
        </div>
        <!-- 项目公告 -->
        <div class="notice"  v-if="currentIndex === 1">
           <el-table
            class="table-head-blue"
            :data="projectNotices"
            style="width: 100%"
            height="300px"
            @row-click="showDetail">
            <el-table-column align="center" prop="xmbh" label="项目编号"></el-table-column>
            <el-table-column align="center" prop="xmmc" label="公告名称"></el-table-column>
            <el-table-column align="center" prop="ggkssj" width="150px" :formatter="formatterTimeP" label="发布日期"></el-table-column>
          </el-table>
        </div>
        <!-- 成交公示 -->
        <div class="notice" v-if="currentIndex === 2">
          <el-table
            class="table-head-blue"
            :data="successNotices"
            style="width: 100%"
            height="300px"
            @row-click="showDetail">
            <el-table-column align="center" prop="cjgsbt" width="200px" label="公告标题"></el-table-column>
            <el-table-column align="center" prop="zcmj" label="面积（亩）"></el-table-column>
            <el-table-column align="center" prop="dqbj" label="价格（元）"></el-table-column>
            <el-table-column align="center" prop="cjgskssj" width="150px" :formatter="formatterTimeS" label="发布日期"></el-table-column>
          </el-table>
        </div>
        <!-- 资金监管公示 -->
        <div class="notice" v-if="currentIndex === 3">
          <el-table
            class="table-head-blue disable"
            :data="supervisionNotices"
            style="width: 100%"
            height="300px"
            @row-click="showDetail">
            <el-table-column align="center" prop="zjjggsbt" width="200px" label="公告标题"></el-table-column>
            <el-table-column align="center" prop="zcmj" label="面积（亩）"></el-table-column>
            <el-table-column align="center" prop="dqbj" label="价格（元）"></el-table-column>
            <el-table-column align="center" prop="zjjggskssj" width="150px" :formatter="formatterTimeF" label="发布日期"></el-table-column>
          </el-table>
        </div>
        <!-- 政策法规 -->
        <div class="notice" v-if="currentIndex === 4">
          <el-table
            class="table-head-blue"
            :data="documents"
            style="width: 100%"
            height="300px"
            @row-click="showDetail">
            <el-table-column align="center" prop="title" label="标题"></el-table-column>
            <el-table-column align="center" prop="createTime" width="150px" :formatter="formatterTime" label="发布日期"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <div class="entries">
      <div class="s-title">
        <span class="decoration"></span>
        <span class="title">快速入口</span>
      </div>
      <div class="entry-con">
        <div class="entry-item" v-for="(item, index) in entries" @click="goExchange(item, index)" :key="index">
          <img :src="item.url" alt="">
          <p>{{item.name}}</p>
        </div>
      </div>
    </div>

    <div class="bidding-lobby">
      <div class="s-title">
        <span class="decoration"></span>
        <span class="title">网络竞价大厅</span>
        <span class="more" @click="goBidding">更多 >></span>
      </div>
      <div class="content">
        <el-tabs v-model="activeName" @tab-click="handleTypeClick">
          <el-tab-pane class="my-table" v-for="item in biddingTypes" :key="item.lx" :label="item.lx" :name="item.bh">
            <el-table
              border
              :data="tableData"
              style="width: 100%"
              height="500px"
              :row-style="handleRowStyle"
              :row-class-name="handleRowClass">
            <el-table-column align="center" prop="xmbh" label="项目编号" width="240px"></el-table-column>
            <el-table-column align="center" prop="xmmc" label="项目名称" width="140px"></el-table-column>
            <el-table-column align="center" prop="jjkssj" label="竞价开始时间" width="160px"></el-table-column>
            <el-table-column align="center" prop="jjjzsj" label="竞价截止时间" width="160px"></el-table-column>
            <el-table-column align="center" prop="gpjg" label="挂牌价格"></el-table-column>
            <el-table-column align="center" prop="dqbj" label="当前报价"></el-table-column>
            <el-table-column align="center" label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="success" size="small" @click="bidding(scope.row)">竞价</el-button>
              </template>
            </el-table-column>
          </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div class="finance">
      <div class="left">
        <div class="s-title">
          <span class="decoration"></span>
          <span class="title">融资备案公示</span>
          <span class="more" @click="handleMore">更多 >></span>
        </div>
        <el-table
          class="table-head-blue"
          :data="financeTableData"
          style="width: 100%"
          height="410px">
          <el-table-column align="center" prop="otherRightCode" label="他项权利证书" width="180px"></el-table-column>
          <el-table-column align="center" prop="propertyAddress" label="融资地区" width="180px"></el-table-column>
          <el-table-column align="center" prop="propertyTypeName" label="抵押类型"></el-table-column>
          <el-table-column align="center" prop="loansLimit" label="贷款金额（元）"></el-table-column>
          <el-table-column align="center" prop="regisDate" label="登记日期" width="170px"></el-table-column>
        </el-table>
      </div>
    </div>

    <div class="document">
      <div class="left">
        <div class="s-title">
          <span class="decoration"></span>
          <span class="title">政策法规</span>
          <span class="more" @click="goPolicys">更多 >></span>
        </div>
        <div class="docs">
          <div class="docs-item" v-for="(item, index) in documents2" :key="index" @click="showNewsDetail(item)">
            <span  class="name">
              <img src="imgs/icon-decoration.png" alt="">
              <span :title="item.title">{{item.title}}</span>
            </span>
            <span style="color: #a5a5a5;">{{item.createTime.slice(0,10)}}</span>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="s-title">
          <span class="decoration"></span>
          <span class="title">文档模板</span>
          <span class="more" @click="goDocuments">更多 >></span>
        </div>
        <div class="docs">
          <div class="docs-item" v-for="(item, index) in templates" :key="index" @click="downloadTemplate(item)">
            <span class="name">
              <img src="imgs/icon-download.png" alt="">
              <span :title="item.title">{{item.title}}</span>
            </span>
            <span style="color: #a5a5a5;">{{item.createTime.slice(0,10)}}</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="s-title">
          <span class="decoration"></span>
          <span class="title">地图导航</span>
        </div>
        <div class="map">
          <marker-map
            style="width: 100%;height: 100%;"
            ref='markermap'
          ></marker-map>
        </div>
      </div>
    </div>

    <bidding-dialog v-if="dialogVisible" :dialogVisible.sync="dialogVisible" :data="currentBid" @close="dialogClose" @submit="submit"></bidding-dialog>
  </div>
</template>

<script>
import { getSuccessAnnouncement, getSupervisionAnnouncement } from '../../api/announcement'
import { getFileLists, downloadFile } from '../../api/download'
import { getRecentNews, getNewsLists } from '../../api/development'
import { getExchangeProData, getPropertyType } from '../../api/exchange'
import { getNoticeList } from "../../api/ApplicationsFor";
import { getDtBidInfo, importBidInfo, getRegionList } from '../../api/projectManage'
import biddingDialog from '../projectManagement/components/biddingDialog'
import { getStore } from '../../js/utils/store'
import { mapGetters } from 'vuex';
import markerMap from '../../components/markMap/markerMap'
import { split } from '../../js/utils/util'
export default {
  components: {
    biddingDialog,
    markerMap
  },
  data() {
    return {
      picNews:[],
      recentNews: [],
      tabs: [
        {label: '新闻中心', name: '5'},
        {label: '项目公告',name: '0'}, {label: '成交公示', name: '1'},
        // {label: '融资备案公告', name: '2'},
        {label: '资金监管公示', name: '3'},
        {label: '政策法规', name: '4'},
      ],
      currentIndex: 0,
      currentTab: {},
      projectNotices: [],
      successNotices: [],
      supervisionNotices: [],
      entries: [
        {name: '耕地', url: 'imgs/icon-earth.png', path: '/home/exchange?index=01'},
        {name: '四荒地', url: 'imgs/icon-wasteland.png', path: '/home/exchange?index=04'},
        {name: '集体建设用地', url: 'imgs/icon-develop.png', path: '/home/exchange?index=07'},
        {name: '农业生产设施设备', url: 'imgs/icon-produce.png', path: '/home/exchange?index=08'},
        {name: '融资贷款', url: 'imgs/icon-finance.png', path: '/user/dydb'},
        {name: '林地', url: 'imgs/icon-treeland.png', path: '/home/exchange?index=03'},
        {name: '宅基地', url: 'imgs/icon-houseland.png', path: '/home/exchange?index=05'},
        {name: '农房', url: 'imgs/icon-house.png', path: '/home/exchange?index=06'},
        {name: '农村产改公示', url: 'imgs/icon-notice.png', path: '/home/productionChange'},
        {name: '农业知识产权', url: 'imgs/icon-techi.png', path: '/home/exchange?index=09'},
      ],
      biddingTypes: [],
      activeName: '0',
      tableData: [],
      financeTableData: [ ],
      form: {
        area:""
      },
      documents: [],
      documents2: [],
      templates: [],
      query:{
         pageIndex:1,
        pageSize:10,
        loansLimitType:"",
        otherRightCode:""
      },
      options: [
        {
          value: "1",
          label: "10万以下",
        },
        {
          value: "2",
          label: "10-50万",
        },
        {
          value: "3",
          label: "50-100万",
        },
        {
          value: "4",
          label: "100-1000万",
        },
        {
          value: "5",
          label: "1000万以上",
        },
      ],
      regionOptions: [],
      props: {
        label: 'xzqmc',
        children: 'list',
        value: 'xzqbh',
        checkStrictly: true,
        emitPath: false
      },
      dialogVisible: false,
      currentBid: {},
      currentBidTab: {},
      biddingInterval: null, // 竞价信息轮询
    }
  },
  computed: mapGetters(['userInfo']),
  mounted() {
    this.getNews();
    this.handleClick(this.tabs[0], 0);
    this.getPolicyLists();
    this.getTemplates();
    this.getFinanceTableData();// 备案公示列表
    this.getRegions();
    this.initPropertyType();
    this.$nextTick(() => {
      // 加载地图
      this.$refs.markermap.initMarkerMap(null);
    })
  },
  destroyed() {
    if(this.biddingInterval) {
      clearInterval(this.biddingInterval)
    }
  },
  methods: {
    async initPropertyType() {
      // 初始化产权类型
      let res = await getPropertyType();
      let {status, data} = res;
      if(status) {
        this.biddingTypes = data;
        this.activeName = this.biddingTypes[0].bh;
        this.handleTypeClick(this.biddingTypes[0]);
      }
    },
    async getRegions() {
      // 获取信息
      let res = await getRegionList();
      let { status,data } = res;
      if(status) {
        let temp = data;
        this.regionOptions = split(temp);
      }
    },
    goback() {
      this.$router.go(-1);
    },
    async getNews() {
      // 获取新闻 带标题图片的
      this.picNews = [];
      let res = await getRecentNews();
      let {code, data} = res;
      if(code == 0) {
        this.picNews = data;
      }
    },
    async getNewsList() {
      this.recentNews = [];
      let params = {
        articleFid: 6,
        current: 1,
        size: 9
      }
      let res = await getNewsLists(params);
      let {code, data} = res;
      if(code == 0) {
        this.recentNews = data.records;
      }
    },
    showNewsDetail(article) {
      // 新闻详情
      if(article.articleType == 1) {
        // 外部链接
        let url = article.mdContent;
        window.open(url, '_blank');
      } else {
        this.$router.push({name: 'newsPage', query: {c: article.id}})
      }
    },
    handleClick(tab, index) {
      // 点击tab事件
      this.currentIndex = index;
      this.currentTab = tab;
      switch(tab.name) {
        case '0':
          // 项目公告
          this.getProjectLists();
          break;
        case '1':
          // 成交公示
          this.getSuccessAnnouncement();
          break;
        // case '2':
        //   this.getFinanceTableData()
        //   // 融资备案公告
        //   break;
        case '3':
          // 资金监管公示
          this.getSupervisionAnnouncement();
          break;
        case '4':
          // 政策法规
          this.getPolicyLists();
          break;
        case '5':
          // 新闻中心
          this.getNewsList();
          break;
        default:
          break;
      }
    },
    async getProjectLists() {
      // 获取项目公告
      this.projectNotices = [];
      let params = {
        pnum: 1,
        psize: 10
      }
      let res = await getExchangeProData(params);
      let {status, data, dataNum} = res;
      if(status) {
        this.projectNotices = data;
      }
    },
    async getSuccessAnnouncement() {
      // 成交公示
      this.successNotices = [];
      let params = {
        pnum: 1,
        psize: 10
      }
      let res = await getSuccessAnnouncement(params);
      let {status, data, dataNum} = res;
      if(status) {
        this.successNotices = data;
      }
    },
    async getSupervisionAnnouncement() {
      // 资金监管
      this.supervisionNotices = [];
      let params = {
        pnum: 0,
        psize: 10
      }
      let res = await getSupervisionAnnouncement(params);
      let {status, data, dataNum} = res;
      if(status) {
        this.supervisionNotices = data;
      }
    },
    async getPolicyLists() {
      // 获取政策法规列表
      this.documents = [];
      let params = {
        articleFid: 1,
        current: 1,
        size: 10
      }
      let res = await getFileLists(params);
      let {code, data} = res;
      if(code == 0) {
        this.documents = data.records;
        this.documents2 = data.records.slice(0,6);
      }
    },
    formatterTime(row) {
      return row.createTime.slice(0,10)
    },
    formatterTimeP(row) {
      return row.ggkssj.slice(0,10)
    },
    formatterTimeS(row) {
      return row.cjgskssj.slice(0,10)
    },
    formatterTimeF(row) {
      return row.zjjggskssj.slice(0,10)
    },
    showDetail(item) {
      // 查看详细信息
      switch(this.currentTab.name) {
        case '0':
          // 项目公告
          this.$router.push({name: 'projectDetail', query: {c: item.xmbh}});
          break;
        case '1':
          // 成交公示
          this.$router.push({name: 'successDetail', query: {c: item.xmbh}});
          break;
        case '2':
          // 融资备案公告
          break;
        case '4':
          // 政策法规
          // this.showPolicyDetail(item.id);
          this.showNewsDetail(item);
          break;
        case '5':
          // 新闻详情
          this.showNewsDetail(item);
          break;
        default:
          break;
      }
    },
    async showPolicyDetail(id) {
      // 查看政策文件详情
      let res = await downloadFile(id);
      let {code, data, msg} = res;
      if(code == 0) {
        // window.location.href = data.fileUrl;
        window.open(data.fileUrl, '_blank')
      } else {
        this.$message.error(msg);
      }
    },
    async downloadTemplate(item) {
      // 下载文档模板
      // let res = await downloadFile(id);
      // let {code, data, msg} = res;
      // if(code == 0) {
      //   // window.location.href = data.fileUrl;
      //   window.open(data.fileUrl, '_blank')
      // } else {
      //   this.$message.error(msg);
      // }
      this.showNewsDetail(item)
    },
    async handleTypeClick(tab) {
      // 网络竞价大厅切换
      this.currentBidTab = tab;
      let params = {
        xzqbh: '',
        cqlxbh: tab.name || tab.bh,
        zdmj: this.form.maxArea,
        pnum: 1,
        psize: 10
      }
      let res = await getDtBidInfo(params);
      let {status, data, dataNum} = res;
      if(status) {
        this.tableData = data;
        this.total = dataNum;
        setTimeout(this.setBiddingInterval, 10);
      }
    },
    setBiddingInterval() {
      if(this.biddingInterval) {
        clearInterval(this.biddingInterval)
      }
      let that = this;
      async function change() {
        let params = {
          xzqbh: '',
          cqlxbh: that.currentBidTab.name || that.currentBidTab.bh,
          zdmj: that.form.maxArea,
          pnum: 1,
          psize: 10
        }
        let res = await getDtBidInfo(params);
        let {status, data, dataNum} = res;
        if(status) {
          that.tableData = data;
          that.total = dataNum;
        }
      }
      that.biddingInterval = setInterval(change, 5000)
    },
    bidding(row) {
      // 竞价
      let token = getStore({name: 'access_token'});
      this.currentBid = row;
      if(token) {
        // 竞价
        this.dialogVisible = true;
      } else {
        this.$confirm('您需要登陆才可以竞价，是否跳转到登陆页?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          localStorage.setItem('backPath', this.$route.fullPath)
          this.$router.push({name: 'login'})
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }
    },
    async submit(val, offer) {
      // 竞价信息提交
      let total = 0;
      if(offer == 0) {
        total = parseFloat(val) + parseFloat(this.currentBid.gpjg)
      } else {
        total = parseFloat(val) + parseFloat(offer);
      }
      let info = {
        xmbh: this.currentBid.xmbh,
        yhid: this.userInfo.userId,
        dqbj: total,
      }
      let params = {
        jsondata: JSON.stringify(info)
      }
      let res = await importBidInfo(params);
      let {status, data, msg} = res;
      if(status) {
        this.$message.success('提交竞价信息成功！');
        this.dialogVisible = false;
        this.handleTypeClick(this.currentBidTab);
      } else {
        this.$message.error(msg);
      }
    },
    async getTemplates() {
      // 获取文档模板
      let params = {
        articleFid: 3,
        articleSid: 7,
        current: 1,
        size: 6
      }
      let res = await getFileLists(params);
      let {code, data} = res;
      if(code == 0) {
        this.templates = data.records;
      }
    },
    dialogClose() {
      this.dialogVisible = false;
      this.handleTypeClick(this.currentBidTab);
    },
    handleRowStyle({row, rowIndex}) {
      // 表格行样式
      // if(row.type == 0) {
      //   return {
      //     color: '#fff'
      //   }
      // } else if(row.type == 1) {
      //   return {
      //     color: '#0bcb0a'
      //   }
      // } else {
      //   return {
      //     color: '#e32525'
      //   }
      // }
    },
    handleRowClass({row, rowIndex}) {
      if(rowIndex % 2) {
        return  'odd'
      }
    },
    goExchange(item, index) {
      // 快速入口
      if(item.path == '/user/dydb') {
        let token = getStore({name: 'access_token'});
        if(token) {
          this.$router.push({path: item.path});
        } else {
          this.$confirm('您需要登陆才可以融资贷款，是否跳转到登录页?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            localStorage.setItem('backPath', item.path)
            this.$router.push({name: 'login'})
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
        }
      } else {
        this.$router.push({path: item.path});
      }
    },

    handleMore(){
      this.$router.push({name:"finance"})
    },

      // 融资备案查询
    queryFinance() {
      this.query.loansLimitType=this.form.money
      this.query.otherRightCode=this.form.code
      this.getFinanceTableData()
    },

    //融资备案公示列表
    async getFinanceTableData(){
      let params=this.query
      let res=await getNoticeList(params)
      if(res.status===200){
        this.financeTableData=res.data.records;
      }
    },
    goBidding() {
      // 网络竞价大厅
      let token = getStore({name: 'access_token'});
      if(token) {
        this.$router.push({path: '/user/jjzxm'});
      } else {
        this.$confirm('您需要登陆才可以查看更多，是否跳转到登录页?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          localStorage.setItem('backPath', '/user/jjzxm')
          this.$router.push({name: 'login'})
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }
    },
    goPolicys() {
      // 政策法规
      this.$router.push({name: 'download', query: {index: 0}});
    },
    goDocuments() {
      // 文档模板
      this.$router.push({name: 'download', query: {index: 2}});
    }
  }
}
</script>


<style lang="less" scoped>
.query /deep/ .el-select>.el-input{
  width: 235px;
}
.homepage {
  padding-top: 34px;
  background: #f2f2f2;
  text-align: left;
  padding-bottom: 55px;
  .swiper {
    width: 100%;
    height: 400px;
    // min-height: 250px;
    text-align: center;
    background: #fff;
    .carousel  {
      height: 100%;
      width: 100%;
    }
    .el-carousel__item {
      img {
        // width: 100%;
        height: auto;
      }
    }
    img {
      // width: 100%;
      // height: 100%;
    }
  }

  .s-title {
    position: relative;
    .more {
      position: absolute;
      top: 0;
      right: 10px;
      color: #999999;
      cursor: pointer;
    }
  }

  .notices {
    margin: 0 auto;
    margin-top: 30px;
    padding: 18px;
    width: 1164px;
    height: 414px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    .left {
      width: 552px;
      height: 411px;
      // background-color: #cacaca;
      .news-swiper {
        position: relative;
        width: 100%;
        height: 100%;
        .title {
          position: absolute;
          left: 0;
          bottom: 0;
          width: calc(100% - 188px);
          margin: 0;
          padding: 10px 170px 10px 18px;
          // padding-left: 18px;
          background: rgba(0,0,0,0.3);
          z-index: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #fff;
          cursor: pointer;
        }
        .el-carousel__item {
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .right {
      margin-left: 25px;
      width: calc(100% - 577px);
      height: 100%;
      .tabs {
        padding-left: 13px;
        width: 100%;
        height: 42px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .tab-item {
          width: auto;
          height: 40px;
          line-height: 20px;
          margin-right: 40px;
          font-size: 18px;
        }
        .tab-item:last-child {
          margin-right: 0;
        }
        .active {
          color: #00a0e9;
          border-bottom: 3px solid #00a0e9;
        }
      }
      .notice {
        padding-right: 10px;
        height: calc(100% - 45px);
        overflow-y: auto;
        .choose {
          cursor: pointer;
        }
        .notice-item {
          display: flex;
          justify-content: flex-start;
          align-items: baseline;
          text-align: left;
          margin-top: 20px;
          .info {
            margin-left: 14px;
            line-height: 30px;
            width: calc(100% - 24px);
            font-size: 14px;
            color: #a5a5a5;
            p:first-child {
              display: flex;
              justify-content: space-between;
              .name {
                font-size: 16px;
                color: #333333;
                display: inline-block;
                width: 70%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
        .notice-item:first-child {
          margin-top: 30px;
        }
        .news-item {
          margin-top: 9px;
        }
        .news-item:first-child {
          margin-top: 20px;
        }
        p {
          margin: 0;
          padding: 0;
        }
        .table-head-blue {
          margin-top: 20px;
        }
      }
    }
  }

  .entries {
    height: 377px;
    width: 1200px;
    background: #fff;
    margin: 0 auto;
    margin-top: 38px;
    .entry-con {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: flex-start;
      flex-wrap: wrap;
      .entry-item {
        box-sizing: border-box;
        width: 240px;
        height: 155px;
        background-color: #ffffff;
        // border: 1px solid #c9c9c9;
        border-top: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        flex-shrink: 0;
        flex-grow: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        p {
          margin-bottom: 24px;
        }
      }
      .entry-item:nth-child(n + 6) {
        border-bottom: 1px solid #e6e6e6;
      }
      .entry-item:nth-child(1), .entry-item:nth-child(6){
        border-left: 1px solid #e6e6e6;
      }
      .entry-item:hover {
        box-shadow: 0px 8px 35px 0px
        rgba(27, 28, 31, 0.3);
        border: solid 1px #e6e6e6;
        z-index: 2;
      }
    }
  }

  .bidding-lobby {
    width: 1200px;
    height: 628px;
    margin: 0 auto;
    margin-top: 38px;
    background: #fff;
    .content {
      width: 100%;
      height: calc(100% - 63px);
      border: solid 1px #b2b2b2;
      .el-tabs {
        height: 100%;
      }
      .describe {
        text-align: right;
        width: 100%;
        height: 63px;
        background-color: #333333;
        line-height: 63px;
        .item {
          margin-right: 52px;
          color: #fff;
          img {
            vertical-align: middle;
            margin-right: 11px;
          }
        }
      }
    }
  }

  .finance {
    width: 1200px;
    // height: 320px;
    height: 474px;
    margin: 0 auto;
    margin-top: 46px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .left {
      // width: 795px;
      width: 100%;
      height: 100%;
      background: #fff;
    }
    .right {
      margin-left: 14px;
      width: calc(100% - 809px);
      height: 100%;
      background: #fff;
      .query {
        padding: 14px 22px 0 14px;
        .el-button {
          width: 124px;
	        height: 36px;
        }
      }
    }
  }

  .document {
    width: 1200px;
    height: 321px;
    margin: 0 auto;
    margin-top: 46px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .left {
      width: 391px;
      height: 100%;
      background: #fff;
    }
    .center {
      width: 391px;
      height: 100%;
      margin-left: 13px;
      background: #fff;
    }
    .right {
      width: calc(100% - 809px);
      margin-left: 14px;
      height: 100%;
      background: #fff;
      .map {
        // margin: 11px 29px 9px 42px;
        // width: 100%;
        padding: 0 20px 35px 20px;
        height: calc(100% - 98px);
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .docs {
      height: calc(100% - 71px);
      width: calc(100% - 10px);
      margin-bottom: 8px;
      overflow-y: auto;
      padding-left: 10px;
      .docs-item {
        width: calc(100% - 8px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 40px;
        color: #333333;
        padding-right: 8px;
        cursor: pointer;
        img {
          margin-right: 9px;
        }
        .name {
          font-size: 15px;
          color: #333333;
          display: inline-block;
          width: 70%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>

<style lang="less">
.swiper {
  .el-carousel__container {
    height: 100%;
  }
}
.news-swiper {
  .el-carousel__indicators--horizontal {
    width: 180px;
    left: 80%;
    text-align: right;
  }
  .el-carousel__indicator--horizontal {
    padding: 8px 4px;
  }
  .el-carousel__button {
    width: 10px;
    height: 3px;
  }
}
.bidding-lobby {
  .el-tabs__header {
    padding-top: 10px;
  }
  .el-tabs__nav-wrap::after {
    height: 0;
  }
  .el-tabs__active-bar {
    width: 0 !important;
  }
  .el-tabs__item {
    padding: 0;
    margin: 0 20px;
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold;
    font-weight: 600;
  }
  .el-tabs__item.is-active {
    border-bottom: none;
  }
  .el-tabs__content {
    height: calc(100% - 65px);
    overflow-y: auto;
  }
}

.my-table {
  .el-table {
    // 解决tooltip出现时页面出现抖动
    // width: 99.99%;
    background:#333333;
  }
  .el-table td, .el-table th.is-leaf {
    border-bottom: 1px solid #ffff;
  }
  .el-table tr {
    height: 62px;
    color: #fff;
  }
  .el-table th, .el-table tr {
    background-color: transparent;
  }
  .el-table tbody tr:hover > td {
    background: #565656;
  }
  .el-table::before {
    height: 0;
  }
  .el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #fff;
  }
  .el-table--border, .el-table--group {
    border: 1px solid #fff;
  }
  .el-table--border::after, .el-table--group::after {
    width: 0;
  }

  .odd {
    background-color: #565656 !important;
  }
  .undo {
    color: #fff !important;
  }
  .doing {
    color: #0bcb0a !important;
  }
  .done {
    color: #e32525 !important;
  }

}

.table-head-blue {
  .el-table__row {
    cursor: pointer;
  }
}
.disable {
  .el-table__row {
    cursor: auto;
  }
}

.finance {
  .has-gutter tr th {
    background-color: #00a0e9;
    color: #fff;
  }
  .el-table td, .el-table th.is-leaf {
    border-bottom: 1px solid #dedede;
  }
}

.bidding-lobby {
  .el-tabs__nav-prev, .el-tabs__nav-next {
    i {
      font-size: 28px;
      margin-top: 8px;
    }
  }
  .el-tabs__nav-wrap.is-scrollable {
    padding: 0 35px;
  }
}
</style>
