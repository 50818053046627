import proj4 from "proj4";
import { register } from "ol/proj/proj4.js";


/**
 *  扩展坐标系，采用proj4的方式
 */
export function registerProj() {
    proj4.defs("EPSG:4490", "+proj=longlat +ellps=GRS80 +no_defs");
    register(proj4);
}