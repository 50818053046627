/**
 * 有毒气体
 */
import {
  Style, Icon, Text, Circle as CircleStyle, Fill, Stroke,
} from 'ol/style';

const normalStyle = new Style({
  image: new Icon({
    scale: 1,
    src: '/img/map1/有毒气体.png',
  }),
});

const clusterStyle = new Style({
  image: new Icon({
    scale: 1,
    src: '/img/map1/有毒气体1.png',
  }),
});

const activeStyle = [normalStyle, new Style({
  image: new Icon({
    scale: 1.2,
    src: '/img/map1/box.png',
    offset: [0, 2],
  }),
})];

export function styleFunction(feature) {
  let style = normalStyle;
  const activeSelect = feature.activeSelect || feature.getProperties().activeSelect;
  if (activeSelect) {
    style = activeStyle;
  }
  return style;
}

// 样式缓存
const styleCache = {};

export function clusterStyleFunction(feature) {
  const size = feature.get('features').length;
  let style = null;
  if (size > 1) {
    let radius = size < 100 ? 8 : 12;
    const tempStyle = styleCache[size];
    if (!tempStyle) {
      // 如果缓存中没有样式，则创建样式
      style = [
        clusterStyle, // 图标
        new Style({
          // 数字样式
          image: new CircleStyle({
            radius: radius,
            stroke: new Stroke({
              color: '#f00',
            }),
            fill: new Fill({
              color: '#f00',
            }),
            displacement: [12, 12],
          }),
          text: new Text({
            text: size.toString(),
            offsetX: 12,
            offsetY: -11,
            font: 'normal 10px MicrosoftYaHei',
            fill: new Fill({
              color: '#fff',
            }),
          }),
        }),
      ];
      styleCache[size] = style;
    } else {
      style = tempStyle;
    }
  } else if (size === 1) {
    const oneFeature = feature.get('features')[0];
    style = styleFunction(oneFeature);
  }
  return style;
}
