<template>
  <div>
    <div id="markerMap"></div>
  </div>
</template>

<script>
import { initMap, getMapMousePosition} from '../../js/map/map';
import { createVectorLayer } from '../../js/map/layer'
import loadBaseLayer from '../../js/map/business/baseLayer';
import { markerLonLat } from '../../js/map/business/simple/markerLonLat';

export default {
  data() {
    return {
      markerMap: null,
      layer:null,
      markerCoordinate: null, // 标记坐标
    };
  },
  mounted() {
    this.markerMap = null;
    this.layer = null;
    this.markerCoordinate = null;
  },
  methods: {
    initMarkerMap(markerCoordinate, type) {
      this.markerMap = initMap('markerMap', window.mapViewConfig); // markerMap为地图容器id
      // 添加影像底图
      const baseLayers = window.imageDatas;
      loadBaseLayer(this.markerMap, baseLayers);
      this.layer = createVectorLayer();
      this.markerMap.addLayer(this.layer);

      let that = this;
      this.markerMap.on('click', (e) => {
        const mousePostion = getMapMousePosition(e);
        if (mousePostion) {
          markerLonLat(that.layer, mousePostion.mouseCoordinate, type);
          that.markerCoordinate = mousePostion.mouseCoordinate;
          // console.log(this.markerCoordinate)
          that.$emit('childFn', that.markerCoordinate);
        }
      });

      this.loadMarkerPoint(markerCoordinate, type);
    },
    loadMarkerPoint(markerCoordinate, type) {
      // 是否存在标记坐标
      if (markerCoordinate && typeof (markerCoordinate[0]) === 'number' && typeof (markerCoordinate[1]) === 'number') {
        markerLonLat(this.layer, markerCoordinate, type);
        // 定位
        const zoom = Math.min(this.markerMap.getView().getMaxZoom(), 18);
        this.markerMap.getView().animate({ center: markerCoordinate }, { zoom });

        this.markerCoordinate = markerCoordinate;
      }
    },
    getMarkerLonLat() { // 返回标记的经纬度坐标
      return this.markerCoordinate;
    },
  },
};
</script>

<style lang="less" scoped>
#markerMap {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>
